

export default {
  name: "Hero",
  props: {
    description: String,
  },
  data() {
    return {
      animation: null,
      showLatinTxt: false
    };
  },
  computed: {
    loadingAnimationFinished() {
      return this.$store.state.loadingAnimationFinished
    },
  },
  watch: {
    // whenever question changes, this function will run
    loadingAnimationFinished(finished) {
      if (finished) {
        this.playAnimation();
      }
    }
  },
  mounted() {
    this.setLottieAnimation();

    if (this.loadingAnimationFinished && !this.showLatinTxt) {
      this.playAnimation();
    }
  },
  methods: {
    setLottieAnimation() {
      this.animation = this.$lottie.loadAnimation({
        name: 'arrowBrush',
        container: this.$refs.animationArrowBrush, // the dom element that will contain the animation
        loop: false,
        autoplay: false,
        path: '/animations/arrow_brush.json', // the path to the animation json
      });
    },
    playAnimation() {
      setTimeout(() => {
        this.animation.play('arrowBrush');

        setTimeout(() => {
          // arrowBrush animation finished
          this.showLatinTxt = true;
        }, 800);
      }, 700); // delay till the logo is parked
    },
  },
}
