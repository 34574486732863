
import {getImageOfSize, getAttributes} from "@/utils/getImagePaths.js";

export default {
  name: "SwiperCarrousel",
  props: {
    slides: Array,
  },
  data() {
    return {
      swiper: null,
      swiperOptions: {
        effect: 'cards',
        loop: true,
        // touchEventsTarget: 'container', // Add this line
        // centeredSlides: true, // Add this line
        // slidesPerView: 2, // Add this line
        cardsEffect: {
          perSlideOffset: 10,
          perSlideRotate: 10,
          slideShadows: false
        },
        runCallbacksOnInit: true,
      }
    };
  },
  mounted() {
    this.initializeSwiper();
  },
  watch: {
    slides() {
      if (this.swiper) {
        this.swiper.params.loopAdditionalSlides = this.slides.length - 1;
      }
    },
  },
  methods: {
    getImageOfSize,
    getAttributes,
    initializeSwiper() {
      this.swiper = new this.$swiper('.swiper', {
        loop: true,
        modules: [this.$swiperModules.EffectCards],
        ...this.swiperOptions
      });

      // Use the 'progress' event to handle opacity updates
      this.swiper.on("progress", () => {
        this.updateOpacity();
      });

      // Use the 'slideChangeTransitionEnd' event to handle opacity updates after slide change
      this.swiper.on("slideChangeTransitionEnd", () => {
        this.updateOpacity();
        this.swiper.el.dispatchEvent(new MouseEvent('mousemove', {bubbles: true}));
      });

      // Initial opacity update
      this.updateOpacity();
    },
    updateOpacity() {
      this.swiper.slides.forEach((slide, index) => {
        let progress = Math.abs(slide.progress);

        // Calculate the new opacity based on the slide progress
        let newOpacity = 0.6 + (1 - progress) * 0.4;

        // Apply the new opacity to the slide
        slide.style.opacity = newOpacity;
      });
    },
  }
};
