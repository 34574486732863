
import {getImageOfSize, getAttributes} from "@/utils/getImagePaths.js";

export default {
  name: "ProjectsGridRow",
  props: {
    workPost: Object,
    contentIndex: Number,
  },
  data() {
    return {
      hover: false,
    };
  },
  methods: {
    getImageOfSize,
    getAttributes,
    hasMoreThanFourWords(title) {
      // Split the string by spaces to get an array of words
      const words = title.trim().split(/\s+/);

      // Check if the length of the words array is greater than 4
      return words.length > 4;
    }
  }
};
