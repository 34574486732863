export function getImageOfSize(object, size) {
  try {
    if (!object || !object.formats || !object.formats[size]) {
      return '';
    }
    return object.formats[size].url || '';
  } catch (e) {
    console.error(`Caught error:`, e);
    return '';
  }
}

export function getPostThumbnailsAttributes(post) {
  if (post
    && post.attributes
    && post.attributes.thumbnails
    && post.attributes.thumbnails.data
    && post.attributes.thumbnails.data[0]) {
    return post.attributes.thumbnails.data[0].attributes;
  } else {
    return null;
  }
}

export function getPostThumbnailAttributes(post) {
  if (post
    && post.attributes
    && post.attributes.thumbnail
    && post.attributes.thumbnail.data) {
    return post.attributes.thumbnail.data.attributes;
  } else {
    return null;
  }
}

export function getImageAttributes(zone) {
  if (zone
    && zone.image
    && zone.image.data
    && zone.image.data.attributes) {
    return zone.image.data.attributes;
  } else {
    return null;
  }
}

export function getMemberPhotoAttributes(member) {
  if (member
    && member.photo
    && member.photo.data
    && member.photo.data.attributes) {
    return member.photo.data.attributes;
  } else {
    return null;
  }
}

export function getAttributes(item) {
  if (item
    && item.attributes) {
    return item.attributes;
  } else {
    return null;
  }
}
