
export default {
  name: "ProjectsGrid",
  props: {
    featuredWorkPosts: Array,
  },
  mounted() {
    // ToDo make this dynamic so it triggers if scaled down/up
    if (this.$device.isMobileOrTablet) {
      this.initScrollTriggerAnimation();
    }
  },
  methods: {
    initScrollTriggerAnimation() {
      const scrollItems = this.$refs.scrollItems;

      scrollItems.forEach((item, index) => {
        this.$gsap.fromTo(
          item.$el,
          {x: index % 2 === 0 ? -150 : 150},
          {
            x: 0,
            // ease: "power2.out", // Add ease property
            scrollTrigger: {
              trigger: item.$el,
              start: "top 100%",
              end: "bottom top",
              scrub: .5,
              // markers: true,
              scroller: this.locoScroll ? this.locoScroll : window,
            },
          }
        );
      });
    },
  },
}
