
import homeQuery from "@/apollo/queries/pages/home";
import workPostsQuery from "~/apollo/queries/work/posts";
import locomotiveScrollMixin from '@/mixins/locomotiveScrollMixin';
import previewModeMixin from '@/mixins/previewModeMixin';

export default {
  name: 'index',
  colorMode: 'light',
  mixins: [locomotiveScrollMixin, previewModeMixin],
  async asyncData({app, store}) {
    const previewMode = store.state.previewMode;
    const {default: MarkdownIt} = await import('markdown-it')
    const md = new MarkdownIt({html: true});

    if (previewMode) {
      await app.apolloProvider.defaultClient.resetStore();
    }

    const responseWorkPosts = await app.apolloProvider.defaultClient.query({
      query: workPostsQuery,
      variables: {
        locale: app.i18n.locale,
      },
    }).catch((err) => {
      console.log(JSON.stringify(err, null, 2));
    });

    const responseHome = await app.apolloProvider.defaultClient.query({
      query: homeQuery,
      variables: {
        locale: app.i18n.locale,
      },
    }).catch((err) => {
      console.log(JSON.stringify(err, null, 2));
    });

    // Process markdown for description
    if (responseHome.data.home.data.attributes.description) {
      responseHome.data.home.data.attributes.description = md.render(responseHome.data.home.data.attributes.description)
    }

    return {
      home: responseHome.data.home,
      workPosts: responseWorkPosts.data.workPosts,
      previewMode,
    };
  },
  computed: {
    featuredWorkPosts() {
      return this.workPosts.data
        .filter(post => post.attributes.featured)
        .map((post, index) => ({...post, featuredIndex: index}));
    }
  },
  data() {
    return {
      home: null,
      workPosts: null,
    };
  },
}
