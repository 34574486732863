
export default {
  name: "MeetTheTeam",
  data() {
    return {
      tl: null,
      tlWiggle: null,
      muppetAnim: null,
      memberIconsVisible: false,
      muppetAnimTriggered: false
    };
  },
  computed: {
    isLocomotiveScrollInitialized() {
      return this.$store.state.isLocomotiveScrollInitialized;
    }
  },
  watch: {
    isLocomotiveScrollInitialized(newVal) {
      if (newVal) {
        // Locomotive Scroll has been initialized
        this.setTrigger();
      }
    }
  },
  mounted() {
    this.tl = this.$gsap.timeline({paused: true});
    this.tl.to(this.$refs.teamList.children, {y: '-100%', stagger: 0.1});
    this.tlWiggle = this.$gsap.timeline({paused: true});
    this.tlWiggle.add([
      this.$gsap.to(this.$refs.teamList.children[0], {x: '-1px', duration: .3}),
      this.$gsap.to(this.$refs.teamList.children[1], {y: '-115%', duration: .6, ease: "power4"}),
      this.$gsap.to(this.$refs.teamList.children[2], {x: '1px', duration: .4})
    ]);
  },
  methods: {
    startWiggleAnimation() {
      this.tlWiggle.play();
    },
    stopWiggleAnimation() {
      this.tlWiggle.reverse();
    },
    startAnimation() {
      this.tl.play();
    },
    setTrigger() {
      const self = this;
      const isMobile = window.matchMedia("(max-width: 768px)").matches;

      this.muppetAnim = this.$ScrollTrigger.create({
        trigger: this.$refs.team,
        start: isMobile ? "top 80%" : "top 60%",
        end: isMobile ? "bottom 80%" : "bottom 60%",
        onToggle() {
          if (!self.muppetAnimTriggered) {
            self.muppetAnimTriggered = true;
            self.startAnimation();
          }
        },
        onEnter() {
          if (!self.muppetAnimTriggered) {
            self.muppetAnimTriggered = true;
            self.startAnimation();
          }
        }
      })
    },
  }
}
