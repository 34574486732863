// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".arrow_Fh1iS{transform:rotate(-150deg)}.motto_9LESy p{--tw-text-opacity:1;color:#969696;color:rgb(150 150 150/var(--tw-text-opacity))}.motto_9LESy strong{--tw-text-opacity:1;color:#000;color:rgb(0 0 0/var(--tw-text-opacity))}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrow": "arrow_Fh1iS",
	"motto": "motto_9LESy"
};
module.exports = ___CSS_LOADER_EXPORT___;
