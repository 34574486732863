
export default {
  name: "CursorDraw",
  data() {
    return {
      ease: 0.75,
    };
  },
  mounted() {
    this.setCursorAnimation()
  },
  methods: {
    setCursorAnimation() {
      /**
       * Mouse Follower (GSAP3)
       * Code by Blake Bowen
       * @link https://codepen.io/osublake/pen/mEpado
       * Adapted for GSAPv3
       * @link https://greensock.com/forums/topic/30599-custom-cursor-with-line-trails/#comment-152815
       */
      const pointer = {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2
      };

      window.addEventListener("mousemove", (event) => {
        pointer.x = event.clientX;
        pointer.y = event.clientY;
      });

      let leader = (prop) => {
        return prop === "x" ? pointer.x : pointer.y;
      }
      // Length of line trail
      const total = 75;

      for (let i = 0; i < total; i++) {
        leader = this.createLine(leader, i);
      }
    },
    createLine(leader) {
      const svgns = "http://www.w3.org/2000/svg";
      let line = document.createElementNS(svgns, "line");

      this.$refs['svg'].appendChild(line);
      this.$gsap.set(line, {x: -1500, y: -750});

      let pos = this.$gsap.getProperty(line);

      this.$gsap.to(line, {
        duration: 10000,
        x: "+=150",
        y: "+=10",
        repeat: -1,
        ease: "expo.inOut",
        modifiers: {
          x: () => {
            let posX = pos("x");
            let leaderX = leader("x");
            let x = posX + (leaderX - posX) * this.ease;
            line.setAttribute("x2", leaderX - x);
            return x;
          },
          y: () => {
            let posY = pos("y");
            let leaderY = leader("y");
            let y = posY + (leaderY - posY) * this.ease;
            line.setAttribute("y2", leaderY - y);
            return y;
          }
        }
      });

      return pos;
    }
  }
}


